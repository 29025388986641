.Contact .lander {
    padding: 80px 0;
    margin-left: 30px;
    margin-right: 30px;
}

.Contact .lander h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    text-align: center;
}

.Contact .lander ul {
    color: #999;
}
