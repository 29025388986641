.Home .lander {
    padding: 80px 0;
    margin-left: 30px;
    margin-right: 30px;
}

.Home .lander h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    text-align: center;
}

.Home .lander h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: DimGray
}

.Home .lander p {
    color: #999;
}


.Home .lander footer {
    color: #999;
    font-style: italic;
}
