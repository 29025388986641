.FlightStatus .lander {
    padding: 80px 0;
    text-align: center;
}

.FlightStatus .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600
}

.FlightStatus .lander p {
    color: #999;
}

.FlightStatus .map {
}

.FlightStatus .fireflyImg {
}
